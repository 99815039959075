.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.App{
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}

/*::-webkit-scrollbar{
  width: 1px;
}*/

div{
  position: relative !important;
}

.parallaxLayer{
  position: absolute !important;
}

#divDrop{
  position: absolute !important;
}